<template>
    <b-row>
        <b-col cols="12">
            <b-form-group :label="$t(labelPrefix+'document')">
                <multiselect
                    v-model="inputVal.doc_type"
                    name="doc_type"
                    :options="Object.keys(getDocumentsList())"
                    :multiple="false"
                    :custom-label="opt => getDocumentsList()[opt]"
                >
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <zw-select-group
                v-model="inputVal.attribute"
                name="attribute"
                :options="getDetectionAttributes() | optionsVV"
                :label-prefix="labelPrefix"
            >
            </zw-select-group>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'CreateCombineFile',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getDocuments']),
        ...mapGetters('Workflow', ['getDetectionAttributes']),
        getDocumentsList() {
            let result = {}
            this.getDocuments().forEach(document => {
                result[document] = this.$t('invoices.document.' + document)
            })
            return result
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>